//$scrollhide-nav-transition-speed: .5s;

nav {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  //transition: transform $scrollhide-nav-transition-speed cubic-bezier(0.86, 0, 0.07, 1);
  z-index: 5000;
display: none;

  &.hidden { 
    //transform: translateY(-100%);
  }
}

