// GLOBAL

body, h1, h2, h3, h4, h5, h6, .bit-widget {
	font-family: 'Barlow Condensed', sans-serif!important;
}


// HEADER

nav{
	padding:1rem;
	background: rgba(34,34,34,.85);
	
	ul.menu {
		background:none;
		
		a {
			font-size: 16px;
			@include breakpoint(medium) {
				font-size: 24px;
			}
			@include breakpoint(large) {
				font-size: 30px;
			}
			font-weight: 700;
			color:#fff;
			text-transform: uppercase;
		}
	}
	
.logo {
	width: 100%;
	height:auto;
	}
}

// SECTIONS GLOBAL

section {
	padding:48px 0;
	@include breakpoint(medium) {
		padding:100px 0;
	}
}

#hero {
	padding:0;
	height:50vh;
	@include breakpoint(medium) {
		height:100vh;
	}
}

#shows, #music {
	background: #434343;
}

#video {
	background: #222;
}

// BANDSINTOWN CUSTOMIZATIONS

.bit-widget {
	
	span.bit-event-list-title  {
		display: none;
	}
	
	.bit-track-button-container, .bit-nav-bar-container {
		display: none;
		@include breakpoint(medium) {
			display: block;
		}
	}
	
	.bit-event {
		text-transform: uppercase;
		font-weight: normal;
		font-size:24px;
		@include breakpoint(large) {
			font-size: 32px;
		}
	}
	
	.bit-venue {
		font-weight: 700;
	}
	
}

//rslides

.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  }

.rslides li {
	-webkit-backface-visibility: hidden;
	position: absolute;
	display: none;
	width: 100%;
	left: 0;
	top: 0;
  }

.rslidesHero li {
	height:50vh; 
	
	@include breakpoint(medium) {
		height:100vh;
	}
  }

.rslides li:first-child {
  position: relative;
  display: block;
  float: left;
  }

.rslidesHero img {
  display: block;
  height: auto;
  float: left;
  width: 100%;
  border: 0;
  }

.rslides_container {
    position: relative;
    //float: left;
    width: 100%;
}

.centered-btns_nav {
    z-index: 3;
    position: absolute;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    top: 50%;
    left: 0;
    opacity: 0.7;
    text-indent: -9999px;
    overflow: hidden;
    text-decoration: none;
    height: 61px;
    width: 38px;
    background: transparent url(/assets/img/buttons.png) no-repeat left top;
    margin-top: -45px;
}

.centered-btns_nav.next {
    left: auto;
    background-position: right top;
    right: 0;
}
	
// Music 
	
#music {
	h1 {
		text-transform: uppercase; 
		color:#f6f6f6;
		line-height: .9;
		margin: 18px 0 36px;
		font-weight: 700;
	}
	p {
		color:#f6f6f6;
	}
	.button {
		text-transform: uppercase; 
		color:#f6f6f6;
		@include breakpoint(medium) {font-size:24px;}
	}
}

//Mailchimp

#mc_embed_signup_scroll {
	h2 { text-transform: uppercase;}
}

// indiegogo 

#indiegogo {
	background-color: #F3F3F3;
	
	h1, h2 {
		text-transform: uppercase;
		margin:0;
		padding:0;
		line-height: 1;
	}
	
	h2 {
		padding-bottom: 36px;
	}
	
	.button {
		font-size:36px;
		text-transform: uppercase;
	}
}

// about 

#about {
	
	h3 { padding-top: 36px;}
	p {
		@include breakpoint(medium) {font-size: 21px;}
	}
}

// Footer

footer {
	text-align: center!important;
	padding:36px!important;
}